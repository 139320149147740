import { Box, Center, Stack, Text } from "@chakra-ui/react";

import "./Entourage.css";

const TITLES_COLORS = {
  title: "teal.500",
  subtitle: "teal.400",
};

function Entourage() {
  return (
    <Center
      id="entourage"
      textAlign={"center"}
      flexDirection={"column"}
      color={"white"}
      py={"8rem"}
    >
      <Text
        color={"teal.600"}
        fontFamily={"Amalfi Coast"}
        fontSize={{ base: "3rem", md: "4rem" }}
        mb={"3rem"}
      >
        Entourage
      </Text>

      <Stack
        spacing={10}
        direction={"column"}
        fontWeight={"300"}
        fontSize={{ base: "1rem", md: "1.2rem" }}
      >
        <Stack
          spacing={{ base: 8, md: 20 }}
          direction={{ base: "column", md: "row" }}
        >
          <Box>
            <Text color={TITLES_COLORS.title} fontWeight={"600"}>
              PARENTS OF THE BRIDE
            </Text>
            <Text>
              Mr. Zachary Baglio
              <br />
              Mrs. Berard Jakeman
            </Text>
          </Box>

          <Box>
            <Text color={TITLES_COLORS.title} fontWeight={"600"}>
              PARENTS OF THE GROOM
            </Text>
            <Text>
              Mr. Anthony Zappa
              <br />
              Mrs. Ezra Sarkisian
            </Text>
          </Box>
        </Stack>

        <Box>
          <Text
            fontSize={"2xl"}
            fontWeight={"600"}
            mb={"1rem"}
            letterSpacing={3}
            color={TITLES_COLORS.title}
          >
            PRINCIPAL SPONSORS
          </Text>

          <Stack
            spacing={{ base: 8, md: 10 }}
            direction={"row"}
            justifyContent={"center"}
          >
            <Stack spacing={4} direction={"column"} textAlign={"right"}>
              <Text>
                Mrs. Āwhina Sabbadin
                <br />
                Mrs. Desislava Lovrić
                <br />
                Mrs. Mwanaidi Giugovaz
              </Text>

              <Box>
                <Text fontWeight={"600"} color={TITLES_COLORS.subtitle}>
                  MAID OF HONOR
                </Text>
                <Text>Ms. Gróa Im</Text>
              </Box>

              <Box>
                <Text fontWeight={"600"} color={TITLES_COLORS.subtitle}>
                  BRIDESMAIDS
                </Text>
                <Text>
                  Ms. Moe Goode
                  <br />
                  Ms. Alexis Han
                  <br />
                  Ms. Alcides Grec
                </Text>
              </Box>
            </Stack>

            <Stack spacing={4} direction={"column"} textAlign={"left"}>
              <Text>
                Mr. Natalia Sharp
                <br />
                Mr. Gwenddydd Jensen
                <br />
                Mr. Laurentino Bager
              </Text>

              <Box>
                <Text fontWeight={"600"} color={TITLES_COLORS.subtitle}>
                  BEST MAN
                </Text>
                <Text>Mr. Yu Liepiņš</Text>
              </Box>

              <Box>
                <Text fontWeight={"600"} color={TITLES_COLORS.subtitle}>
                  GROOMSMEN
                </Text>
                <Text>
                  Mr. Ami Keyes
                  <br />
                  Mr. Marie-Rose Fortunato
                  <br />
                  Mr. Raghu Tuff
                </Text>
              </Box>
            </Stack>
          </Stack>
        </Box>

        <Box>
          <Text
            fontSize={"2xl"}
            fontWeight={"600"}
            mb={"1rem"}
            letterSpacing={3}
            color={TITLES_COLORS.title}
          >
            SECONDARY SPONSORS
          </Text>

          <Stack spacing={4} direction={"column"}>
            <Box>
              <Text fontWeight={"600"} color={TITLES_COLORS.subtitle}>
                CORD
              </Text>
              <Text>
                Mr. Ayelen Lyall
                <br />
                Ms. Marijani Little
              </Text>
            </Box>

            <Stack spacing={10} direction={"row"} justifyContent={"center"}>
              <Box>
                <Text fontWeight={"600"} color={TITLES_COLORS.subtitle}>
                  CANDLE
                </Text>
                <Text>
                  Ms. Xhemal Porsche
                  <br />
                  Mr. Seung Trucco
                </Text>
              </Box>

              <Box>
                <Text fontWeight={"600"} color={TITLES_COLORS.subtitle}>
                  VEIL
                </Text>
                <Text>
                  Ms. Nuadha Kaube
                  <br />
                  Mr. Maria Sachs
                </Text>
              </Box>
            </Stack>

            <Stack spacing={5}>
              <Box>
                <Text fontWeight={"600"} color={TITLES_COLORS.subtitle}>
                  RING BEARER
                </Text>
                <Text>Svanhildr Fukuzawa</Text>
              </Box>

              <Box>
                <Text fontWeight={"600"} color={TITLES_COLORS.subtitle}>
                  BIBLE BEARER
                </Text>
                <Text>Hadiza Negrini</Text>
              </Box>

              <Box>
                <Text fontWeight={"600"} color={TITLES_COLORS.subtitle}>
                  COIN BEARER
                </Text>
                <Text>Sven Reena</Text>
              </Box>

              <Box>
                <Text fontWeight={"600"} color={TITLES_COLORS.subtitle}>
                  FLOWER GIRLS
                </Text>
                <Text>
                  Rosemonde Ailbhe
                  <br />
                  Hüsniye Imriška
                  <br />
                  Estienne Alexandros
                </Text>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Center>
  );
}

export default Entourage;
